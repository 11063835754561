import React from 'react';

const Price = (item) => {
    let className = item?.className || 'sm-info';
    let prefix = item?.priceQualifier || item?.price_qualifier;
    let suffix = '';
    let price = (item?.priceDetails || item?.price || '').toLocaleString()
    if (item.status === 'Let' || item.status === 'To Let') {
        prefix = '';
        suffix = item?.priceQualifier || item?.price_qualifier;
    }

    if(prefix == "None"){
        prefix = null;   
    }
    if(suffix == "None"){
        suffix = null;   
    }

    return (
        <>
             {prefix && <span className="price-qualifier">{prefix}&nbsp;</span>}
            <span className={className}>
                £{price}
            </span>&nbsp; 
            {suffix && <span className="price-qualifier">&nbsp;{suffix}</span>}
        </>
    )
}

export default Price;
